import React from "react";
import { Toolbar, createTheme, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  NavBarRootDiv,
  StyledAppBar,
  StyledTypography,
  StyledButton,
  BulkDiv,
  RootCenterDiv,
} from "../../css/StyledComponents";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function Header(props) {
  const { flag, fUser, profileName, filterValueSetter, filterValue } = props;

  const theme = createTheme({
    overrides: {
      MuiButton: {
        label: {
          justifyContent: "center",
        },
      },
    },
  });

  const handleFilterChange = (event) => {
    const value = event.target.value;
    filterValueSetter(value);
  };

  if (flag == 1) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-end",
              }}
            >
              My Courses
            </StyledTypography>
            <Link
              exact
              to="/newCourse"
              style={{ textDecoration: "none", justifySelf: "flex-end" }}
            >
              <StyledButton variant="contained" size="small">
                + New Course
              </StyledButton>
            </Link>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 2) {
    return (
      <RootCenterDiv>
        <ThemeProvider theme={theme}>
          <Link exact to="/newTest" style={{ textDecoration: "none" }}>
            <StyledButton variant="contained">New Test</StyledButton>
          </Link>
          {/* <Link exact to="/newAssignment" style={{ textDecoration: "none" }}>
            <StyledButton variant="contained">New Assignment</StyledButton>
          </Link> */}
          <Link exact to="/newQuestion" style={{ textDecoration: "none" }}>
            <StyledButton variant="contained">Add Questions</StyledButton>
          </Link>
          {/* <Link exact to="/newCourse" style={{ textDecoration: "none" }}>
            <StyledButton variant="contained">Add Courses</StyledButton>
          </Link> */}
          <Link
            exact
            to="/generateQuestions"
            style={{ textDecoration: "none" }}
          >
            <StyledButton variant="contained">AI Questions</StyledButton>
          </Link>
        </ThemeProvider>
      </RootCenterDiv>
    );
  } else if (flag == 3) {
    return (
      <NavBarRootDiv>
        <StyledAppBar
          position="static"
          style={{ padding: "0px 1.5rem 0px 1.5rem" }}
        >
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <TextField
              id="outlined-search"
              label="Search..."
              type="search"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
              value={filterValue}
              onChange={handleFilterChange}
              variant="standard"
              style={{ width: "40%" }}
            />
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "center",
              }}
            >
              Tests
            </StyledTypography>
            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <Link
                exact
                to="/newTest"
                style={{ textDecoration: "none", justifySelf: "flex-end" }}
              >
                <StyledButton variant="contained" size="small">
                  + Create Test
                </StyledButton>
              </Link>
            ) : null}
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 4) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <StyledTypography style={{ color: "black", fontWeight: "bold" }}>
              Publish Test
            </StyledTypography>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 5) {
    const filterDialog = props.filterDialog;
    const setFilterDialog = props.setFilterDialog;
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-end",
              }}
            >
              Question Bank
            </StyledTypography>
            <div style={{ justifySelf: "flex-end" }}>
              <StyledButton
                variant="contained"
                size="small"
                onClick={() => {
                  setFilterDialog(!filterDialog);
                }}
              >
                FILTER
              </StyledButton>
              <Link exact to="/newQuestion" style={{ textDecoration: "none" }}>
                <StyledButton variant="contained" size="small">
                  + New Question
                </StyledButton>
              </Link>
              <Link
                exact
                to="/generateQuestions"
                style={{ textDecoration: "none" }}
              >
                <StyledButton variant="contained" size="small">
                  + AI Questions
                </StyledButton>
              </Link>
            </div>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 6) {
    return (
      <NavBarRootDiv className="fixed" style={{ width: "100%" }}>
        <StyledAppBar
          position="static"
          style={{ display: "flex", padding: "0 1rem" }}
        >
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            {" "}
            <TextField
              id="outlined-search"
              label="Search..."
              type="search"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
              value={filterValue}
              onChange={handleFilterChange}
              variant="standard"
              style={{ width: "40%" }}
            />
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              Students
            </StyledTypography>
            <div style={{ justifySelf: "flex-end" }}>
              <BulkDiv>
                <Link
                  exact
                  to="/addBulkStudents"
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton variant="contained" size="small">
                    + Add Bulk Students
                  </StyledButton>
                </Link>
                <Link
                  exact
                  to="/studentProfile"
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton variant="contained" size="small">
                    + Add Student
                  </StyledButton>
                </Link>
              </BulkDiv>
            </div>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 7) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-end",
              }}
            >
              Assignments
            </StyledTypography>
            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <Link
                exact
                to="/newAssignment"
                style={{ textDecoration: "none", justifySelf: "flex-end" }}
              >
                <StyledButton variant="contained" size="small">
                  + Create Assignment
                </StyledButton>
              </Link>
            ) : null}
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 8) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar>
            <StyledTypography style={{ color: "black", fontWeight: "bold" }}>
              Create Course
            </StyledTypography>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 9) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{ width: "100%", display: "grid", gridAutoFlow: "column" }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-end",
              }}
            >
              Teachers
            </StyledTypography>
            <div style={{ justifySelf: "flex-end" }}>
              <BulkDiv>
                <Link
                  exact
                  to="/addBulkTeachers"
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton variant="contained" size="small">
                    + Add Bulk Teachers
                  </StyledButton>
                </Link>
                <Link
                  exact
                  to="/teacherProfile"
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton variant="contained" size="small">
                    + Add Teacher
                  </StyledButton>
                </Link>
              </BulkDiv>
            </div>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 10) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{
              width: "100%",
              display: "grid",
              gridAutoFlow: "column",
            }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-end",
              }}
            >
              Registrations
            </StyledTypography>
            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <Link
                exact
                to="/newExam"
                style={{ textDecoration: "none", justifySelf: "flex-end" }}
              >
                <StyledButton variant="contained" size="small">
                  + Create Registration
                </StyledButton>
              </Link>
            ) : null}
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  } else if (flag == 11) {
    return (
      <NavBarRootDiv>
        <StyledAppBar position="static">
          <Toolbar
            style={{
              width: "100%",
              display: "grid",
              gridAutoFlow: "column",
            }}
          >
            <StyledTypography
              style={{
                color: "black",
                fontWeight: "bold",
                justifySelf: "flex-center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {profileName}
            </StyledTypography>
          </Toolbar>
        </StyledAppBar>
      </NavBarRootDiv>
    );
  }
}
