import React, { useState, useEffect, useRef } from "react";
import { StyledQuestionTextField } from "../../css/StyledComponents";

const languageMap = {
  ODIA: "or",
  HINDI: "hi",
  BENGALI: "bn",
  TAMIL: "ta",
  TELUGU: "te",
  KANNADA: "kn",
  MALAYALAM: "ml",
  GUJARATI: "gu",
  PUNJABI: "pa",
  MARATHI: "mr",
};

const TransliterateInput = ({
  name,
  value,
  language,
  onChange,
  disabled,
  placeholder,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const lastWordInfo = useRef({ word: "", startPosition: 0 });
  const wrapperRef = useRef(null);
  const suggestionRefs = useRef([]);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchTransliteration = async (word) => {
    const langCode = languageMap[language?.toUpperCase()];

    if (!langCode) {
      setSuggestions([]);
      return;
    }

    const url = `https://www.google.com/inputtools/request?ime=transliteration_en_${langCode}&num=5&cp=0&cs=0&ie=utf-8&oe=utf-8&app=jsapi&text=${word}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data[0] === "SUCCESS" && data[1]?.[0]?.[1]?.length > 0) {
        setSuggestions(data[1][0][1]);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      setSuggestions([]);
    }
  };

  const handleChange = (e) => {
    const text = e.target.value;
    setActiveSuggestion(0);
    setShowSuggestions(true);
    const cursorPosition = e.target.selectionStart;

    const textBeforeCursor = text.slice(0, cursorPosition);

    const lastChar = textBeforeCursor.slice(-1);
    if (lastChar !== " " && lastChar !== "\n") {
      const lastWordMatch = textBeforeCursor.match(/(\S+)$/);

      if (lastWordMatch) {
        const lastWord = lastWordMatch[1];
        const startPosition = cursorPosition - lastWord.length;
        lastWordInfo.current = { word: lastWord, startPosition };

        fetchTransliteration(lastWord);
        setShowSuggestions(true);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
    if (onChange) {
      onChange(text);
    }
  };
  const scrollToActiveSuggestion = (index) => {
    if (suggestionRefs.current[index]) {
      suggestionRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && showSuggestions && suggestions.length > 0) {
      e.preventDefault();
      setActiveSuggestion((prev) => {
        const nextIndex = prev < suggestions.length - 1 ? prev + 1 : prev;
        scrollToActiveSuggestion(nextIndex);
        return nextIndex;
      });
    } else if (
      e.key === "ArrowUp" &&
      showSuggestions &&
      suggestions.length > 0
    ) {
      e.preventDefault();
      setActiveSuggestion((prev) => {
        const nextIndex = prev > 0 ? prev - 1 : prev;
        scrollToActiveSuggestion(nextIndex);
        return nextIndex;
      });
    } else if (e.key === "Enter" && showSuggestions && suggestions.length > 0) {
      e.preventDefault();
      selectSuggestion(suggestions[activeSuggestion]);
    }
  };

  const handleClick = (suggestion) => {
    selectSuggestion(suggestion);
  };

  const selectSuggestion = (suggestion) => {
    const { startPosition, word } = lastWordInfo.current;

    const newText =
      value.slice(0, startPosition) +
      suggestion +
      value.slice(startPosition + word.length);
    const newCursorPosition = startPosition + suggestion.length;

    setShowSuggestions(false);

    if (onChange) {
      onChange(newText);
    }

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }
    }, 0);
  };

  return (
    <div ref={wrapperRef} style={{ position: "relative" }}>
      <StyledQuestionTextField
        inputRef={inputRef}
        multiline
        name={name}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder={placeholder}
      />
      {showSuggestions && value && (
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: 0,
            // position: "absolute",
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            maxHeight: "150px",
            overflowY: "auto",
            marginTop: 0,
            borderRadius: "4px",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              ref={(el) => (suggestionRefs.current[index] = el)}
              onClick={() => handleClick(suggestion)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  index === activeSuggestion ? "#f0f0f0" : "white",
                fontFamily: "Noto Sans, sans-serif",
                padding: "8px",
              }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TransliterateInput;
