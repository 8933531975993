import React, { useState } from "react";
import Header from "../header/Header";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";

import StudentList from "./studentList/StudentList";
import { styled } from "@mui/material/styles";

const Students = ({ fUser }) => {
  const headerFlag = 6;
  const [filter, setFilter] = React.useState("");

  const filterValueSetter = React.useCallback((value) => {
    setFilter(value);
  }, []);
  return (
    <RootDiv className="rootDiv">
      <div className="header" style={{ width: "100%" }}>
        <Header
          flag={headerFlag}
          filterValueSetter={filterValueSetter}
          filterValue={filter}
          style={{ width: "100%" }}
        />
      </div>

      {/* <StyledGrid item container>
          <StyledGrid item xs={false} sm={1} />
          <StyledGrid item xs={12} sm={10}>
            <Search flag={flag} />
          </StyledGrid>
          <StyledGrid item xs={false} sm={1} />
        </StyledGrid> */}
      <div style={{ marginTop: "4.5rem" }}>
        <StyledGrid item xs={12} sm={10}>
          <StudentList fUser={fUser} filterValue={filter} />
        </StyledGrid>
      </div>
    </RootDiv>
  );
};

export default Students;
