import {
  Button,
  TextField,
  ListItemText,
  Select,
  Typography,
  Checkbox,
  InputLabel,
  MenuItem,
  Box,
  Chip,
  Autocomplete,
  FormHelperText,
  DialogActions,
  DialogContent,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/system";

export const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0rem 2rem 2rem 2rem",

  //Style for the Student Profile
  "& .mobilePinArea": {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .schoolListStyle": {
    width: "100%",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));
export const RootDiv = styled("div")(({ theme }) => ({
  minHeight: 800,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
  },

  //Style for the Teacher Profile
  "& .preferedExamsConainer": {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingTop: "0.5rem",
    },
  },
  "& .uploadFileConatainer": {
    [theme.breakpoints.down(800)]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
}));
export const TextAreaHeader = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "justify",
  display: "flex",
  alignItems: "center",
  fontSize: 18,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));
export const TextAreaDiv = styled("div")(({ theme }) => ({
  display: "flex",
  //   flexDirection: "column",
  flexWrap: "wrap",
  [theme.breakpoints.down(900)]: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  "& .GradeDiv": {
    display: "flex",
    flexDirection: "column",
  },
}));
export const TextAreaContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  [theme.breakpoints.down(900)]: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .kycDetailsSub": {
    display: "flex",
    gap: "2rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down(900)]: {
      // display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "1rem",
    },
  },
}));
export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "350px",
  margin: "10px 7px 0px 7px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    margin: "5px 0px",
  },
}));

export const SelectDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "350px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#1F5156",
  fontSize: "19px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "17px",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: "350px",
  margin: "10px 7px 0px 7px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    margin: "5px 0px",
  },
}));

export const ButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const buttonStyles = {
  width: "fit-content",
  margin: "7px 7px",
  position: "sticky",
  fontSize: "15px",
  textTransform: "initial",
  backgroundColor: "#1f5156",
  color: "white",
  "&:hover": {
    backgroundColor: "#f5b921",
    color: "black",
  },
};

export const StyledButton = styled(Button)(({ theme }) => ({
  ...buttonStyles,
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  alignItems: "flex-start",
  marginTop: "2px",
  marginRight: "13px",
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({}));
export const StyledListItemText = styled(ListItemText)(({ theme }) => ({}));
export const StyledChip = styled(Chip)(({ theme }) => ({ margin: 1 }));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));
export const StyledImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "350px",
  margin: "10px 7px 0px 7px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    margin: "5px 0px 5px 0px",
  },
}));

export const SchoolDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "5px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  marginLeft: 20,
}));
export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({}));
export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({}));
export const StyledDialog = styled(Dialog)(({ theme }) => ({}));
export const DialogDiv = styled("div")(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: "1vh 0.5vw",
}));
