import React, { useState, useEffect } from "react";
import { Paper, Tooltip } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { gql, useQuery, NetworkStatus } from "@apollo/client";
import { loader } from "graphql.macro";
// import SearchBar from "material-ui-search-bar";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTypography,
  StyledTableRow,
  RootDiv,
  TableParentDiv,
} from "../../css/StyledComponents";

import { LoadingSpinner } from "../../constants/loadingSpinner";

const userGetInstitutesQuery = loader(
  "../../graphqlCalls/institute/userGetAllInstitutes.gql"
);

const InstituteList = (props) => {
  const [paidInstitutes, setPaidInstitutes] = useState([]);
  const [unpaidInstitutes, setUnpaidInstitutes] = useState([]);
  const [paidRows, setPaidRows] = useState([]);
  const [unPaidRows, setUnPaidRows] = useState([]);
  const [paidSearched, setPaidSearched] = useState("");
  const [unPaidSearched, setUnPaidSearched] = useState("");

  const fUser = props.fUser;

  useEffect(() => {
    requestPaidSearch(" ");
    requestUnPaidSearch(" ");
  }, []);

  const { data, error, loading, refetch, networkStatus } = useQuery(
    userGetInstitutesQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onCompleted: ({ userGetAllInstitutes }) => {
        setPaidInstitutes(userGetAllInstitutes.paid);
        setPaidRows(userGetAllInstitutes.paid);
        setUnpaidInstitutes(userGetAllInstitutes.unpaid);
        setUnPaidRows(userGetAllInstitutes.unpaid);
      },
      onError: (error) => {
        alert("Something went wrong");
      },
    }
  );

  const requestPaidSearch = (searchedValue) => {
    const filteredRows = paidInstitutes.filter((row) => {
      return row.portrait.alias.first
        .toLowerCase()
        .includes(searchedValue.toLowerCase());
    });
    setPaidRows(filteredRows);
  };
  const cancelPaidSearch = () => {
    setPaidSearched("");
    requestPaidSearch(paidSearched);
  };

  const requestUnPaidSearch = (searchedValue) => {
    const filteredRows = unpaidInstitutes.filter((row) => {
      return row.portrait.alias.first
        .toLowerCase()
        .includes(searchedValue.toLowerCase());
    });
    setUnPaidRows(filteredRows);
  };

  const cancelUnPaidSearch = () => {
    setUnPaidSearched("");
    requestUnPaidSearch(unPaidSearched);
  };

  const onSort = (type) => {
    const unPaidSorted = unpaidInstitutes.slice().sort((a, b) => {
      const isReversed =
        type === "ascending" ? 1 : type === "descending" ? -1 : 0;
      return (
        isReversed *
        a.portrait.alias.first.localeCompare(b.portrait.alias.first)
      );
    });
    const paidSorted = paidInstitutes.slice().sort((a, b) => {
      const isReversed =
        type === "ascending" ? 1 : type === "descending" ? -1 : 0;
      return (
        isReversed *
        a.portrait.alias.first.localeCompare(b.portrait.alias.first)
      );
    });
    setUnPaidRows(paidSorted);
    setUnPaidRows(unPaidSorted);
  };

  return (
    <RootDiv>
      <StyledButton onClick={() => onSort("ascending")}>Sort A-Z</StyledButton>
      <StyledButton onClick={() => onSort("descending")}>Sort Z-A</StyledButton>
      {/* <StyledTypography variant="h6" style={{ fontWeight: "bold", margin: 10 }}>
        Registered Institutes
      </StyledTypography>
      <SearchBar
        placeholder="Search By Institute Name"
        value={paidSearched}
        onChange={(searchedValue) => requestPaidSearch(searchedValue)}
        onCancelSearch={() => cancelPaidSearch()}
        defaultValue=" "
      />
      <StyledTableContainer component={Paper}>
        <StyledTable >
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {paidRows.map((insti, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{insti.portrait.alias.first}</StyledTableCell>
                <StyledTableCell>
                  {
                    <Link
                      exact
                      to={"/institute"}
                      state={{   institute: insti}}
                      style={{ textDecoration: "none" }}
                    >
                      <Tooltip title="View">
                        <StyledButton
                          // size="large"
                          startIcon={<Visibility />}
                        >
                          View
                        </StyledButton>
                      </Tooltip>
                    </Link>
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      <StyledTypography variant="h6" style={{ fontWeight: "bold", margin: 10 }}>
        Not Yet Registered Institutes
      </StyledTypography>
      <p>
        (if you are willing to access the institute questions and test
        series,please register)
      </p> */}

      {/* <SearchBar
        placeholder="Search By Institute Name"
        value={unPaidSearched}
        onChange={(searchedValue) => requestUnPaidSearch(searchedValue)}
        onCancelSearch={() => cancelUnPaidSearch()}
        defaultValue=" "
      /> */}
      <TableParentDiv>
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {unPaidRows.map((insti, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {insti.portrait.alias.first}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Link
                        exact
                        to={"/institute"}
                        state={{ institute: insti }}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="View">
                          <StyledButton startIcon={<Visibility />}>
                            View
                          </StyledButton>
                        </Tooltip>
                      </Link>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
      </TableParentDiv>
      <br />
      {LoadingSpinner(loading || !fUser)}
    </RootDiv>
  );
};

export default InstituteList;
