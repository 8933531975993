import React, { useState } from "react";
import { Paper, FormControl } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";

import { topics } from "../../../constants/topics";
import { gradesList } from "../../../constants/gradesList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
import { subjectList } from "../../../constants/subjects";

import "./createAssignment.css";
import {
  HeaderDiv,
  StyledTypography,
  TextAreaDiv,
  StyledTextField,
  StyledInputLabel,
  StyledSelect,
  StyledBox,
  StyledChip,
  StyledMenuItem,
  StyledCheckbox,
  StyledListItemText,
  DropDownDiv,
  StyledTable,
  StyledTableContainer,
  StyledAutocomplete,
  StyledButton,
  StyledTableRow,
  StyledTableCell,
  StyledTableHead,
  StyledTableBody,
  TableDiv,
  TableButtonDiv,
  StyledTableTextField,
} from "../../../css/StyledComponents";
const createAssignmentMutation = loader(
  "../../../graphqlCalls/assignment/UserSaveNewAssignment.gql"
);
const getAssignmentsQuery = loader(
  "../../../graphqlCalls/assignment/userGetAllAssignments.gql"
);
const teacherGetNoOfAssignmentQuestionsMutation = loader(
  "../../../graphqlCalls/assignment/TeacherGetNoOfAssignmentQuestions.gql"
);

const initialFormValues = {
  assignmentName: "",
  //   assignmentDate: new Date(),
  assignmentDate: "",
  // assignmentDuration: "",
  // totalMarks: 0,
  Beginner: {
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
  },
  Intermediate: {
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
  },
  Advance: {
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
  },
  grades: [],
  bloomTags: [],
  primaryTopic: [],
  secondaryTopic: [],
  subject: "Mathematics",
};

const initialNoOfQues = {
  Beginner: {
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
  },
  Intermediate: {
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
  },
  Advance: {
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
  },
};
const initialMarks = {
  Beginner: {
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Intermediate: {
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Advance: {
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
};

function CreateAssignment(props) {
  const fUser = props.fUser;
  const navigate = useNavigate();

  const [formValues, setValues] = useState(initialFormValues);
  const [noOfQues, setNoOfQues] = useState(initialNoOfQues);
  const [quesMarks, setQuesMarks] = useState(initialMarks);

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;

  let pTopic = Object.keys(topics);
  pTopic.sort((a, b) => a.localeCompare(b));
  let sTopic = [];
  for (let i = 0; i < formValues.primaryTopic.length; i++) {
    let primTopic = formValues.primaryTopic[i];
    let secTopics = Object.keys(topics[primTopic]);
    for (let j = 0; j < secTopics.length; j++) {
      let secTopic = secTopics[j];
      let topicObj = {
        primaryTopic: primTopic,
        secondaryTopic: secTopic,
      };
      // console.log(pTopic, sTopic);
      let topicObjStr = JSON.stringify(topicObj);
      sTopic.push(topicObjStr);
    }
  }
  for (let k = 0; k < formValues.secondaryTopic.length; k++) {
    let secTopic = formValues.secondaryTopic[k];
    let secTopicObj = JSON.parse(secTopic);
    let sResult = sTopic.some((st) => {
      let stObj = JSON.parse(st);
      return stObj.secondaryTopic === secTopicObj.secondaryTopic;
    });
    if (!sResult) {
      let index = formValues.secondaryTopic.indexOf(secTopic);
      if (index > -1) {
        formValues.secondaryTopic.splice(index, 1);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleBeginInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Beginner: { ...formValues.Beginner, [name]: parseInt(value) },
    });
  };
  const handleInterInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Intermediate: { ...formValues.Intermediate, [name]: parseInt(value) },
    });
  };
  const handleAdvInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Advance: { ...formValues.Advance, [name]: parseInt(value) },
    });
  };
  const handleMksBegCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Beginner: {
        ...quesMarks.Beginner,
        [name]: { ...quesMarks.Beginner[name], correct: value },
      },
    });
  };
  const handleMksBegWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Beginner: {
        ...quesMarks.Beginner,
        [name]: { ...quesMarks.Beginner[name], wrong: value },
      },
    });
  };
  const handleMksIntermCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Intermediate: {
        ...quesMarks.Intermediate,
        [name]: { ...quesMarks.Intermediate[name], correct: value },
      },
    });
  };
  const handleMksIntermWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Intermediate: {
        ...quesMarks.Intermediate,
        [name]: { ...quesMarks.Intermediate[name], wrong: value },
      },
    });
  };
  const handleMksAdvCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Advance: {
        ...quesMarks.Advance,
        [name]: { ...quesMarks.Advance[name], correct: value },
      },
    });
  };
  const handleMksAdvWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Advance: {
        ...quesMarks.Advance,
        [name]: { ...quesMarks.Advance[name], wrong: value },
      },
    });
  };
  const parseIntQuesNos = (quesNosIp) => {
    let parsedQuesNos = quesNosIp;
    for (var quesType in quesNosIp) {
      if (quesNosIp.hasOwnProperty(quesType)) {
        parsedQuesNos[quesType] = quesNosIp[quesType]
          ? parseInt(quesNosIp[quesType])
          : 0;
      }
    }
    return parsedQuesNos;
  };
  const parseIntQuesMarks = (quesMarksIp) => {
    let parsedQuesMarks = initialMarks;
    for (var quesLevel in quesMarksIp) {
      if (quesMarksIp.hasOwnProperty(quesLevel)) {
        for (var quesType in quesMarksIp[quesLevel]) {
          if (quesMarksIp[quesLevel].hasOwnProperty(quesType)) {
            for (var answerType in quesMarksIp[quesLevel][quesType]) {
              if (quesMarksIp[quesLevel][quesType].hasOwnProperty(answerType)) {
                parsedQuesMarks[quesLevel][quesType][answerType] = quesMarksIp[
                  quesLevel
                ][quesType][answerType]
                  ? parseInt(quesMarksIp[quesLevel][quesType][answerType])
                  : 0;
              }
            }
          }
        }
      }
    }
    return parsedQuesMarks;
  };
  const JsonParseTopics = () => {
    const submittedTopics = formValues.secondaryTopic.map((secTopic) => {
      const secTopicObj = JSON.parse(secTopic);
      return {
        primaryTopic: secTopicObj.primaryTopic,
        secondaryTopic: secTopicObj.secondaryTopic,
      };
    });
    for (let i = 0; i < formValues.primaryTopic.length; i++) {
      let found = submittedTopics.some(
        (el) => el.primaryTopic === formValues.primaryTopic[i]
      );
      if (!found)
        submittedTopics.push({
          primaryTopic: formValues.primaryTopic[i],
          secondaryTopic: "",
        });
    }
    return submittedTopics;
  };
  const [
    CreateAssignmentMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createAssignmentMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(err);
      // console.log(JSON.stringify(err, null, 2));
    },
    onCompleted: () => {
      NotificationsSuccess("Submitted!");
    },
  });

  const [
    GetNoOfAssignmentQuestionsMutation,
    { loading: gettingQues, error: errorGettingQues, data: dataGettingQues },
  ] = useMutation(teacherGetNoOfAssignmentQuestionsMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(err);
      // refetch()
    },
    onCompleted: ({ TeacherGetNoOfAssignmentQuestions }) => {
      // console.log("TeacherGetNoOfAssignmentQuestions", TeacherGetNoOfAssignmentQuestions);
      setNoOfQues(TeacherGetNoOfAssignmentQuestions);
    },
  });

  const updateCacheCreating = (client, { data: { UserSaveNewAssignment } }) => {
    const data = client.readQuery({
      query: getAssignmentsQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
      },
    });
    if (data) {
      const newAssignment = UserSaveNewAssignment;
      // console.log("assignment data", data);
      const newAssignmentList = {
        userGetAllAssignments: [newAssignment, ...data.userGetAllAssignments],
      };
      client.writeQuery({
        query: getAssignmentsQuery,
        variables: {
          userId: fUser._id,
          userT: fUser.role,
        },
        data: newAssignmentList,
      });
    }
  };
  const getNoOfQues = async () => {
    if (gettingQues) return;
    try {
      const callInfo = await GetNoOfAssignmentQuestionsMutation({
        // fetchPolicy: "network-only",
        variables: {
          input: {
            teacherId: fUser ? fUser._id : null,
            assignment: {
              grade: formValues.grades,
              bloomTags: formValues.bloomTags,
              topics: JsonParseTopics(),
              subject: formValues.subject,
            },
          },
        },
      });
    } catch (error) {
      // console.log("error", error);
      // alert("Something went wrong");
      alert(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); //whats this for?
    if (creating) return;
    try {
      const callInfo = await CreateAssignmentMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            assignment: {
              name: formValues.assignmentName,
              ofDate: formValues.assignmentDate,
              // duration: formValues.assignmentDuration,
              grade: formValues.grades,
              bloomTags: formValues.bloomTags,
              topics: JsonParseTopics(),
              quesLevels: {
                Beginner: parseIntQuesNos(formValues.Beginner),
                Intermediate: parseIntQuesNos(formValues.Intermediate),
                Advance: parseIntQuesNos(formValues.Advance),
              },
              quesMarks: parseIntQuesMarks(quesMarks),
              subject: formValues.subject,
            },
            // topicData: [topicDataTypeIp],
          },
        },
        // onError: (err) => {
        //   console.log("err", err);
        // },
        update: updateCacheCreating,
      });
      // console.log("callInfo", callInfo);
      navigate("/assignment", {
        state: {
          id: callInfo.data.UserSaveNewAssignment._id,
        },
      });
    } catch (error) {
      // console.log(JSON.stringify(error, null, 2));
      // alert("Something went wrong");
      alert(error);
    }

    // console.log(formValues);
  };

  const quesTable = [
    {
      handleChange: handleBeginInputChange,
      stateName: "Beginner",
      rowName: "Beginner",
      mksCorrectChange: handleMksBegCorrectInputChange,
      mksWrongChange: handleMksBegWrongInputChange,
    },
    {
      handleChange: handleInterInputChange,
      stateName: "Intermediate",
      rowName: "Intermediary",
      mksCorrectChange: handleMksIntermCorrectInputChange,
      mksWrongChange: handleMksIntermWrongInputChange,
    },
    {
      handleChange: handleAdvInputChange,
      stateName: "Advance",
      rowName: "Advanced",
      mksCorrectChange: handleMksAdvCorrectInputChange,
      mksWrongChange: handleMksAdvWrongInputChange,
    },
  ];
  const bloomTagsList = [
    "Create",
    "Evaluate",
    "Analyze",
    "Apply",
    "Understand",
    "Remember",
  ];

  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      {NotificationsContainer()}
      <HeaderDiv>
        <StyledTypography variant="h6">Create Assignment</StyledTypography>
      </HeaderDiv>
      <TextAreaDiv>
        <StyledTextField
          name="assignmentName"
          variant="filled"
          label="Assignment Name"
          value={formValues.assignmentName}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
          // InputLabelProps={{ className: classes.labelColor }}
          // InputProps={{ className: classes.input }}
        />
        <StyledTextField
          name="assignmentDate"
          variant="filled"
          label="Assignment Date"
          value={formValues.assignmentDate}
          onChange={handleInputChange}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          // InputLabelProps={{ className: classes.labelColor }}
          // InputProps={{ className: classes.input }}
        />
        <FormControl>
          <StyledInputLabel htmlFor="grade">Grade:</StyledInputLabel>
          <StyledSelect
            name="grades"
            id="grade"
            // variant="outlined"
            multiple
            label="Grade:"
            value={formValues.grades}
            onChange={handleInputChange}
            renderValue={(selected) => (
              <StyledBox>
                {selected.map((value) => (
                  <StyledChip key={value} label={value} />
                ))}
              </StyledBox>
            )}
            MenuProps={MenuProps}
          >
            {gradesList.map((grade, index) => {
              return (
                <StyledMenuItem key={index} value={grade}>
                  <StyledCheckbox
                    checked={formValues.grades.indexOf(grade) > -1}
                  />
                  <StyledListItemText primary={grade} />
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </FormControl>
      </TextAreaDiv>

      {/* Dropdowns start here */}

      <DropDownDiv>
        <FormControl>
          <StyledInputLabel shrink>Primary Topic:</StyledInputLabel>
          <StyledAutocomplete
            multiple
            label="Primary Topic:"
            options={pTopic}
            disableCloseOnSelect
            // disabled={(!editMode && !newMode) || editing || creating}
            // name="primaryTopic"
            value={formValues.primaryTopic}
            getOptionLabel={(option) => {
              // console.log("ptopic option", option);
              return option;
            }}
            onChange={(event, value) => {
              setValues({ ...formValues, primaryTopic: value });
            }}
            // // onChange={handleInputChange}
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>
            //     <StyledCheckbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {option}
            //   </React.Fragment>
            // )}
            renderInput={(params) => <StyledTextField {...params} />}
          />
        </FormControl>
        <FormControl>
          <StyledInputLabel shrink>Select Secondary Topic:</StyledInputLabel>
          <StyledAutocomplete
            multiple
            options={sTopic}
            disableCloseOnSelect
            // disabled={(!editMode && !newMode) || editing || creating}
            value={formValues.secondaryTopic}
            getOptionLabel={(option) => {
              let jsonOption = JSON.parse(option);
              return jsonOption.secondaryTopic;
            }}
            onChange={(event, value) => {
              setValues({ ...formValues, secondaryTopic: value });
            }}
            // onChange={handleInputChange}
            // renderOption={(option, { selected }) => {
            //   let jsonOption = JSON.parse(option);
            //   return (
            //     <React.Fragment>
            //       <StyledCheckbox
            //         icon={icon}
            //         checkedIcon={checkedIcon}
            //         style={{ marginRight: 8 }}
            //         checked={selected}
            //       />
            //       {jsonOption.secondaryTopic}
            //     </React.Fragment>
            //   );
            // }}
            renderInput={(params) => <StyledTextField {...params} />}
          />
        </FormControl>
      </DropDownDiv>
      <DropDownDiv>
        <FormControl>
          <StyledInputLabel>Bloom Taxonomy:</StyledInputLabel>
          <StyledSelect
            name="bloomTags"
            id="bloomTag"
            multiple
            label="Bloom Taxonomy:"
            value={formValues.bloomTags}
            onChange={handleInputChange}
            renderValue={(selected) => (
              <StyledBox>
                {selected.map((value) => (
                  <StyledChip key={value} label={value} />
                ))}
              </StyledBox>
            )}
            MenuProps={MenuProps}
          >
            {bloomTagsList.map((bloomTag, index) => {
              return (
                <StyledMenuItem key={index} value={bloomTag}>
                  <StyledCheckbox
                    checked={formValues.bloomTags.indexOf(bloomTag) > -1}
                  />
                  <StyledListItemText primary={bloomTag} />
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </FormControl>

        <FormControl>
          <StyledInputLabel>Subject:</StyledInputLabel>
          <StyledSelect
            label="Subject:"
            name="subject"
            value={formValues.subject}
            onChange={handleInputChange}
            MenuProps={MenuProps}
          >
            {subjectList.map((subject, index) => {
              return (
                <StyledMenuItem key={index} value={subject}>
                  {subject}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </FormControl>
      </DropDownDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StyledButton
          variant="contained"
          onClick={async () => {
            await getNoOfQues();
          }}
          disabled={creating || gettingQues} //make sure disabled
        >
          Get No Of available Questions
        </StyledButton>
      </div>

      {/* Table starts here */}

      <div>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="customized table">
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">
                  Obj. Single Sol.
                </StyledTableCell>
                <StyledTableCell align="center">
                  Obj. Multiple Sol.
                </StyledTableCell>
                <StyledTableCell align="center">Subjective</StyledTableCell>
                <StyledTableCell align="center">Integral</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>

            <StyledTableBody>
              {quesTable.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {item.rowName}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={formValues[item.stateName]["ObjSingleSol"]}
                          name="ObjSingleSol"
                          onChange={item.handleChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={noOfQues[item.stateName]["ObjSingleSol"]}
                          disabled
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={formValues[item.stateName]["ObjMultiSol"]}
                          name="ObjMultiSol"
                          onChange={item.handleChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={noOfQues[item.stateName]["ObjMultiSol"]}
                          disabled
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={formValues[item.stateName]["Subjective"]}
                          name="Subjective"
                          onChange={item.handleChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={noOfQues[item.stateName]["Subjective"]}
                          disabled
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={formValues[item.stateName]["Integral"]}
                          name="Integral"
                          onChange={item.handleChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={noOfQues[item.stateName]["Integral"]}
                          disabled
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <StyledTableTextField
                        type="number"
                        disabled
                        value={
                          parseInt(formValues[item.stateName]["ObjSingleSol"]) +
                          parseInt(formValues[item.stateName]["ObjMultiSol"]) +
                          parseInt(formValues[item.stateName]["Subjective"]) +
                          parseInt(formValues[item.stateName]["Integral"])
                        }
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                        variant="outlined"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>

        <StyledTypography variant="h5">Marks per Question</StyledTypography>

        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="customized table">
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">
                  Obj. Single Sol.
                </StyledTableCell>
                <StyledTableCell align="center">
                  Obj. Multiple Sol.
                </StyledTableCell>
                <StyledTableCell align="center">Subjective</StyledTableCell>
                <StyledTableCell align="center">Integral</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {quesTable.map((item, index) => {
                return (
                  <StyledTableRow key={`mks${item.rowName}${index}`}>
                    <StyledTableCell component="th" scope="row">
                      {item.rowName}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["ObjSingleSol"]["correct"]
                          }
                          name="ObjSingleSol"
                          onChange={item.mksCorrectChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["ObjSingleSol"]["wrong"]
                          }
                          name="ObjSingleSol"
                          onChange={item.mksWrongChange}
                          InputProps={{ inputProps: { max: 0 } }}
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["ObjMultiSol"]["correct"]
                          }
                          name="ObjMultiSol"
                          onChange={item.mksCorrectChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["ObjMultiSol"]["wrong"]
                          }
                          name="ObjMultiSol"
                          onChange={item.mksWrongChange}
                          InputProps={{ inputProps: { max: 0 } }}
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["Subjective"]["correct"]
                          }
                          name="Subjective"
                          onChange={item.mksCorrectChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["Subjective"]["wrong"]
                          }
                          name="Subjective"
                          onChange={item.mksWrongChange}
                          InputProps={{ inputProps: { max: 0 } }}
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <TableDiv>
                        <StyledTableTextField
                          type="number"
                          value={
                            quesMarks[item.stateName]["Integral"]["correct"]
                          }
                          name="Integral"
                          onChange={item.mksCorrectChange}
                          InputProps={{ inputProps: { min: 0 } }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          variant="outlined"
                        />
                        <StyledTableTextField
                          type="number"
                          value={quesMarks[item.stateName]["Integral"]["wrong"]}
                          name="Integral"
                          onChange={item.mksWrongChange}
                          InputProps={{ inputProps: { max: 0 } }}
                          variant="outlined"
                        />
                      </TableDiv>
                    </StyledTableCell>

                    <StyledTableCell align="center"></StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
        <TableButtonDiv>
          <StyledButton
            type="submit"
            variant="contained"
            value="Submit"
            disabled={creating || gettingQues} //make sure disabled
          >
            submit
          </StyledButton>
        </TableButtonDiv>
      </div>
    </form>
  );
}

export default CreateAssignment;
