  
  export const peerRelationshipList = [
    { "Highly Social": "HIGHLY_SOCIAL" },
    { "Moderately Social": "MODERATELY_SOCIAL" },
    { "Limitedly Social": "LIMITEDLY_SOCIAL" },
  ];


  export const learningPaceList = [
    { "Fast Learners": "FAST_LEARNERS" },
    { "Average Learners": "AVERAGE_LEARNERS" },
    { "Slow Learners": "SLOW_LEARNERS" },
  ];