import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Edit,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { createFilterOptions } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import {
  StyledButton,
  StyledTextField,
  StyledSelect,
  StyledMenuItem,
  StyledInputLabel,
  StyledTypography,
  StyledFormHelperText,
  StyledAutocomplete,
  RootDiv,
  TextAreaDiv,
  SelectDiv,
  SchoolDiv,
  ButtonDiv,
  TextAreaHeader,
  MainContainer,
} from "../profileStyle/style";
import Header from "../../header/Header";
import { examsList } from "../../../constants/examsList";
import { gradesList } from "../../../constants/gradesList";
import { schoolList } from "../../../constants/schoolList";
import { schoolDocumentList } from "../../../constants/schoolDocumentList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
import { learningPaceList, peerRelationshipList } from "../../../constants/studentProfileConstants";

const studentGetProfileQuery = loader(
  "../../../graphqlCalls/student/studentGetProfile.gql"
);
const createStudentMutation = loader(
  "../../../graphqlCalls/student/UserAddStudent.gql"
);
const getStudentsQuery = loader(
  "../../../graphqlCalls/student/userGetAllStudents.gql"
);
const userGetStudentQuery = loader(
  "../../../graphqlCalls/student/userGetAStudent.gql"
);
const studentExistsQuery = loader(
  "../../../graphqlCalls/student/userStudExists.gql"
);
const userEditStudentMutation = loader(
  "../../../graphqlCalls/student/UserEditStudent.gql"
);
const studentEditProfileMutation = loader(
  "../../../graphqlCalls/student/StudentEditProfile.gql"
);
const initialFormValues = {
  studId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  grade: "",
  // category: "",
  sMail: "",
  email: "",
  sMobileNo: "",
  pFirstName: "",
  pMiddleName: "",
  pLastName: "",
  relation: "",
  pMobileNo: "",
  pMail: "",
  address1: "",
  address2: "",
  pinCode: 0,
  // instituteName: "",
  docType: "",
  docNo: "",
  peerRelationship: "",
  learningPace: "",
  docFile64: "",
  // pastPerformance: [],
  compiExams: [],
  schoolName: "",
  sPinCode: 0,
  schoolDocType: "",
  schoolDocFile64: "",
  schoolAdmissionRec64: "",
};

export default function StudentProfile(props) {
  // console.log("StudentProfile props", props);
  const fUser = props.fUser;
  const { state } = useLocation();

  const navigate = useNavigate();
  const [formValues, setValues] = useState(initialFormValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const [editMode, setEditMode] = useState(false);
  const [editable, setEditable] = useState(false);
  const [newMode, setNewMode] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [studentExists, setStudentExists] = useState(true);
  const documentList = [
    "PAN Card",
    "AADHAAR Card",
    "Driving License",
    "Passport",
  ];
  // const schoolDocumentList = [
  //   "School Identity Card",
  //   "AADHAAR Card and School Admission Fee Receipt",
  // ];

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const radioIcon = <RadioButtonUncheckedOutlined fontSize="small" />;
  const radioCheckedIcon = <RadioButtonCheckedOutlined fontSize="small" />;
  let Exams = examsList;
  Exams.sort(
    (a, b) => a.localeCompare(b) //using String.prototype.localCompare()
  );
  // let Schools = schoolList;
  // Schools.sort((a, b) => a.localeCompare(b));

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 75,
  });

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadDocFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          schoolDocFile64: result,
        });
      }
    });
  };
  const onUploadReceiptFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          schoolAdmissionRec64: result,
        });
      }
    });
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          docFile64: result,
        });
        setFileName(target.files[0]);
      }
    });
  };

  const { data, error, loading, refetch, networkStatus } = useQuery(
    userGetStudentQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        sId: state ? state.id : null,
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
        // userEmail: "chinmay.irock@gmail.com",
      },
      skip:
        !state ||
        !fUser ||
        (fUser.role != "Teacher" && fUser.role != "Institute"),
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetAStudent }) => {
        setNewMode(false);
        userGetAStudent.studRegUnderTc ? setEditable(true) : setEditable(false);
        setValues({
          ...formValues,
          studId: userGetAStudent.student._id,
          firstName: userGetAStudent.student.portrait.alias.first,
          middleName: userGetAStudent.student.portrait.alias.middle,
          lastName: userGetAStudent.student.portrait.alias.last,
          grade: userGetAStudent.student.portrait.grade,
          peerRelationship: userGetAStudent.peerRelationship,
          learningPace: userGetAStudent.learningPace,
          email: userGetAStudent.student.portrait.pingAt.virtualAdd,
          sMobileNo: userGetAStudent.student.portrait.pingAt.cell,
          pFirstName: userGetAStudent.student.portrait.parent.alias.first,
          pMiddleName: userGetAStudent.student.portrait.parent.alias.middle,
          pLastName: userGetAStudent.student.portrait.parent.alias.last,
          relation: userGetAStudent.student.portrait.parent.relation,
          pMobileNo: userGetAStudent.student.portrait.parent.pingAt.cell,
          pMail: userGetAStudent.student.portrait.parent.pingAt.virtualAdd,
          address1: userGetAStudent.student.portrait.address.line1,
          address2: userGetAStudent.student.portrait.address.line2,
          pinCode: userGetAStudent.student.portrait.address.pinCode,
          // instituteName: userGetAStudent.student.portrait.institute.name,
          docType: userGetAStudent.student.portrait.docType,
          docNo: userGetAStudent.student.portrait.docNo,
          docFile64: userGetAStudent.student.portrait.docFile64,
          // pastPerformance: [],
          compiExams: userGetAStudent.student.preferredCompiExam,
          schoolDocType: userGetAStudent.student.portrait.academic.docType,
          schoolDocFile64: userGetAStudent.student.portrait.academic.docFile64,
          schoolAdmissionRec64:
            userGetAStudent.student.portrait.academic.admissionRec64,
          schoolName: userGetAStudent.student.portrait.academic.schoolName,
          sPinCode: userGetAStudent.student.portrait.academic.schoolPincode,
        });
      },
    }
  );
  const { studData } = useQuery(studentGetProfileQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      sId: fUser ? fUser._id : null,
    },
    skip: !state || !fUser || fUser.role != "Student",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: ({ studentGetProfile }) => {
      setNewMode(false);
      setEditable(true);
      setValues({
        ...formValues,
        studId: studentGetProfile._id,
        firstName: studentGetProfile.portrait.alias.first,
        middleName: studentGetProfile.portrait.alias.middle,
        lastName: studentGetProfile.portrait.alias.last,
        grade: studentGetProfile.portrait.grade,
        email: studentGetProfile.portrait.pingAt.virtualAdd,
        sMobileNo: studentGetProfile.portrait.pingAt.cell,
        pFirstName: studentGetProfile.portrait.parent.alias.first,
        pMiddleName: studentGetProfile.portrait.parent.alias.middle,
        pLastName: studentGetProfile.portrait.parent.alias.last,
        relation: studentGetProfile.portrait.parent.relation,
        pMobileNo: studentGetProfile.portrait.parent.pingAt.cell,
        pMail: studentGetProfile.portrait.parent.pingAt.virtualAdd,
        address1: studentGetProfile.portrait.address.line1,
        address2: studentGetProfile.portrait.address.line2,
        pinCode: studentGetProfile.portrait.address.pinCode,
        // instituteName: studentGetProfile.portrait.institute.name,
        docType: studentGetProfile.portrait.docType,
        docNo: studentGetProfile.portrait.docNo,
        docFile64: studentGetProfile.portrait.docFile64,
        // pastPerformance: [],
        compiExams: studentGetProfile.preferredCompiExam,
        schoolDocType: studentGetProfile.portrait.academic.docType,
        schoolDocFile64: studentGetProfile.portrait.academic.docFile64,
        schoolAdmissionRec64:
          studentGetProfile.portrait.academic.admissionRec64,
        schoolName: studentGetProfile.portrait.academic.schoolName,
        sPinCode: studentGetProfile.portrait.academic.schoolPincode,
      });
    },
  });
  const [studentExistsCheck, { loading: searching, data: studentExistsData }] =
    useLazyQuery(studentExistsQuery, {
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
        sMail: formValues.sMail,
      },
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userStudExists }) => {
        if (userStudExists) {
          setNewMode(false);
          setStudentExists(true);
          // userStudExists.studRegUnderTc
          //   ? setEditMode(true)
          //   : setEditMode(false);
          userStudExists.studRegUnderTc
            ? setEditable(true)
            : setEditable(false);
          setValues({
            ...formValues,
            studId: userStudExists.studCat.student._id,
            firstName: userStudExists.studCat.student.portrait.alias.first,
            middleName: userStudExists.studCat.student.portrait.alias.middle,
            lastName: userStudExists.studCat.student.portrait.alias.last,
            grade: userStudExists.studCat.student.portrait.grade,
            peerRelationship: userStudExists.studCat.peerRelationship,
            learningPace: userStudExists.studCat.learningPace,
            email: userStudExists.studCat.student.portrait.pingAt.virtualAdd,
            sMobileNo: userStudExists.studCat.student.portrait.pingAt.cell,
            pFirstName:
              userStudExists.studCat.student.portrait.parent.alias.first,
            pMiddleName:
              userStudExists.studCat.student.portrait.parent.alias.middle,
            pLastName:
              userStudExists.studCat.student.portrait.parent.alias.last,
            relation: userStudExists.studCat.student.portrait.parent.relation,
            pMobileNo:
              userStudExists.studCat.student.portrait.parent.pingAt.cell,
            pMail:
              userStudExists.studCat.student.portrait.parent.pingAt.virtualAdd,
            address1: userStudExists.studCat.student.portrait.address.line1,
            address2: userStudExists.studCat.student.portrait.address.line2,
            pinCode: userStudExists.studCat.student.portrait.address.pinCode,
            // instituteName:
            //   userStudExists.studCat.student.portrait.institute.name,
            docType: userStudExists.studCat.student.portrait.docType,
            docNo: userStudExists.studCat.student.portrait.docNo,
            docFile64: userStudExists.studCat.student.portrait.docFile64,
            // pastPerformance: [],
            compiExams: userStudExists.studCat.student.preferredCompiExam,
            schoolDocType:
              userStudExists.studCat.student.portrait.academic.docType,
            schoolDocFile64:
              userStudExists.studCat.student.portrait.academic.docFile64,
            schoolAdmissionRec64:
              userStudExists.studCat.student.portrait.academic.admissionRec64,
            schoolName:
              userStudExists.studCat.student.portrait.academic.schoolName,
            sPinCode:
              userStudExists.studCat.student.portrait.academic.schoolPincode,
          });
        } else {
          setStudentExists(false);
        }
      },
    });
  const [
    CreateStudentMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createStudentMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(
        err.message == "Error: Duplicate Email,User Already Exists!"
          ? err.message
          : "Something went wrong"
      );
    },
    onCompleted: () => {
      NotificationsSuccess("Submitted!");
      setEditable(true);
    },
  });

  const [
    UserEditStudentMutation,
    { loading: editing, error: errorEditing, data: dataEditing },
  ] = useMutation(userEditStudentMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Edited!");
    },
  });

  const [
    StudentEditProfileMutation,
    {
      loading: editingProfile,
      error: errorEditingProfile,
      data: dataEditingProfile,
    },
  ] = useMutation(studentEditProfileMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Edited!");
    },
  });

  const updateCacheCreating = (client, { data: { UserAddStudent } }) => {
    const data = client.readQuery({
      query: getStudentsQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
      },
    });
    const newStudent = UserAddStudent;
    if (data) {
      const newStudentList = {
        userGetAllStudents: [newStudent, ...data.userGetAllStudents],
      };
      client.writeQuery({
        query: getStudentsQuery,
        variables: {
          userId: fUser._id,
          userT: fUser.role,
        },
        data: newStudentList,
      });
    }
    setValues({
      ...formValues,
      studId: UserAddStudent.student._id,
    });
    setNewMode(false);
  };
  const updateCacheEditing = (client, { data: UserEditStudent }) => {
    const wt = client.writeQuery({
      query: userGetStudentQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
        sId: formValues.studId,
      },
      data: { userGetAStudent: UserEditStudent },
    });
    setEditMode(false);
  };

  const updateCacheStudentEditing = (client, { data: StudentEditProfile }) => {
    const wt = client.writeQuery({
      query: studentGetProfileQuery,
      variables: {
        sId: formValues.studId,
      },
      data: { studentGetProfile: StudentEditProfile },
    });
    setEditMode(false);
  };
  const submitContent = async () => {
    if (creating) return;
    try {
      const callInfo = await CreateStudentMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            email: formValues.email,
            grade: formValues.grade,
            peerRelationship: formValues.peerRelationship,
            learningPace: formValues.learningPace,
            sMobileNo: formValues.sMobileNo,
            pFirstName: formValues.pFirstName,
            pMiddleName: formValues.pMiddleName,
            pLastName: formValues.pLastName,
            relation: formValues.relation,
            pMobileNo: formValues.pMobileNo,
            pMail: formValues.pMail,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            // instituteName: formValues.instituteName,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
            academic: {
              docType: formValues.schoolDocType,
              docFile64: formValues.schoolDocFile64,
              admissionRec64: formValues.schoolAdmissionRec64,
              schoolName: formValues.schoolName,
              schoolPincode: parseInt(formValues.sPinCode),
            },
          },
        },
        update: updateCacheCreating,
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const userEditContent = async () => {
    try {
      const callInfo = await UserEditStudentMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            studentId: formValues.studId,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            grade: formValues.grade,
            sMobileNo: formValues.sMobileNo,
            pFirstName: formValues.pFirstName,
            pMiddleName: formValues.pMiddleName,
            pLastName: formValues.pLastName,
            relation: formValues.relation,
            pMobileNo: formValues.pMobileNo,
            pMail: formValues.pMail,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            // instituteName: formValues.instituteName,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
            academic: {
              docType: formValues.schoolDocType,
              docFile64: formValues.schoolDocFile64,
              admissionRec64: formValues.schoolAdmissionRec64,
              schoolName: formValues.schoolName,
              schoolPincode: parseInt(formValues.sPinCode),
            },
            peerRelationship: formValues.peerRelationship,
            learningPace: formValues.learningPace,
          },
        },
        update: updateCacheEditing,
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };

  const studentEditContent = async () => {
    try {
      const callInfo = await StudentEditProfileMutation({
        variables: {
          input: {
            studentId: formValues.studId,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            grade: formValues.grade,
            sMobileNo: formValues.sMobileNo,
            pFirstName: formValues.pFirstName,
            pMiddleName: formValues.pMiddleName,
            pLastName: formValues.pLastName,
            relation: formValues.relation,
            pMobileNo: formValues.pMobileNo,
            pMail: formValues.pMail,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            // instituteName: formValues.instituteName,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
            academic: {
              docType: formValues.schoolDocType,
              docFile64: formValues.schoolDocFile64,
              admissionRec64: formValues.schoolAdmissionRec64,
              schoolName: formValues.schoolName,
              schoolPincode: parseInt(formValues.sPinCode),
            },
          },
        },
        update: updateCacheStudentEditing,
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };


  useEffect(() => {
    if (!state && fUser.role === "Student") navigate("/", { replace: true });
  }, [navigate, state]);

  if (!state) {
    if (fUser.role === "Student") {
      return null;
    }
  }

  return (
    <>
      {" "}
      <Header flag={11} fUser={fUser} profileName={"Student Profile"} />
      <MainContainer>
        <RootDiv>
          {NotificationsContainer()}
          {!newMode && editable ? (
            <StyledButton
              size="large"
              variant="contained"
              startIcon={<Edit />}
              disabled={
                loading || editing || creating || searching || editingProfile
              }
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              Edit
            </StyledButton>
          ) : null}
          <TextAreaHeader>
            <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
              Student's Details
            </StyledTypography>
          </TextAreaHeader>
          <TextAreaHeader>
            <StyledTextField
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              disabled={
                !newMode || editing || creating || searching || editingProfile
              }
              variant="filled"
              label="Email:"
            />
            <StyledTextField
              name="sMobileNo"
              value={formValues.sMobileNo}
              onChange={handleInputChange}
              variant="filled"
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              label="Mobile No:"
            />
          </TextAreaHeader>
          <TextAreaDiv className="GradeDiv" style={{ flexDirection: "column" }}>
            <StyledInputLabel shrink htmlFor="grade">
              Grade:
            </StyledInputLabel>
            <SelectDiv>
              <StyledSelect
                name="grade"
                value={formValues.grade}
                onChange={handleInputChange}
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                //
                style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
              >
                {gradesList.map((grade, index) => {
                  return (
                    <StyledMenuItem key={index} value={grade}>
                      {grade}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </SelectDiv>

            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <SelectDiv>
                <StyledInputLabel shrink htmlFor="grade">
                  Peer Relationships:
                </StyledInputLabel>
                <StyledSelect
                  name="peerRelationship"
                  value={formValues.peerRelationship}
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  onChange={handleInputChange}
                  style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
                >
                  {peerRelationshipList.map((item) => {
                    
                    const [key, value] = Object.entries(item)[0];

                    return (
                      <StyledMenuItem key={value} value={value}>
                        {key} 
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </SelectDiv>
            ) : null}
            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <SelectDiv>
                <StyledInputLabel shrink htmlFor="grade">
                  Learning Pace:
                </StyledInputLabel>
                <StyledSelect
                  name="learningPace"
                  value={formValues.learningPace}
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  onChange={handleInputChange}
                  style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
                >
                  {learningPaceList.map(item => {
                   
                    const [key, value] = Object.entries(item)[0];

                    return (
                      <StyledMenuItem key={value} value={value}>
                        {key}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </SelectDiv>
            ) : null}
          </TextAreaDiv>
          <TextAreaDiv>
            <StyledTextField
              name="firstName"
              value={formValues.firstName}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              variant="filled"
              label="First Name:"
            />
            <StyledTextField
              name="middleName"
              value={formValues.middleName}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              variant="filled"
              label="Middle Name:"
            />
            <StyledTextField
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              variant="filled"
              label="Last Name:"
            />
          </TextAreaDiv>

          <TextAreaHeader>
            <StyledTypography
              variant="h5"
              style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
            >
              Parent's/Guardian's Details
            </StyledTypography>
          </TextAreaHeader>
          <TextAreaDiv>
            <StyledTextField
              name="pFirstName"
              variant="filled"
              value={formValues.pFirstName}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              label="First Name:"
            />
            <StyledTextField
              name="pMiddleName"
              variant="filled"
              value={formValues.pMiddleName}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              label="Middle Name:"
            />
            <StyledTextField
              name="pLastName"
              variant="filled"
              onChange={handleInputChange}
              value={formValues.pLastName}
              label="Last Name:"
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
            />
          </TextAreaDiv>
          <TextAreaDiv style={{ flexDirection: "column", gap: "1rem" }}>
            <div className="selectionArea">
              <SelectDiv style={{ flexDirection: "column" }}>
                <StyledInputLabel shrink htmlFor="grade">
                  Relation with Student:
                </StyledInputLabel>
                <StyledSelect
                  name="relation"
                  // multiple
                  value={formValues.relation}
                  disabled={
                    (!editMode && !newMode) || editing || creating || searching
                  }
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    marginLeft: "1vw",
                  }}
                >
                  <StyledMenuItem value={"Father"}>Father</StyledMenuItem>
                  <StyledMenuItem value={"Mother"}>Mother</StyledMenuItem>
                  <StyledMenuItem value={"Guardian"}>Guardian</StyledMenuItem>
                </StyledSelect>
              </SelectDiv>
            </div>
            <div className="mobilePinArea">
              <StyledTextField
                name="pMobileNo"
                value={formValues.pMobileNo}
                onChange={handleInputChange}
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                variant="filled"
                label="Mobile No:"
              />
              <StyledTextField
                name="pMail"
                value={formValues.pMail}
                onChange={handleInputChange}
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                variant="filled"
                label="Email:"
              />
            </div>
          </TextAreaDiv>
          <TextAreaDiv>
            <StyledTextField
              name="address1"
              variant="filled"
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              value={formValues.address1}
              onChange={handleInputChange}
              label="Address Line 1:"
            />
            <StyledTextField
              name="address2"
              variant="filled"
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              value={formValues.address2}
              onChange={handleInputChange}
              label="Address Line 2:"
            />
            <StyledTextField
              name="pinCode"
              variant="filled"
              type="number"
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              value={formValues.pinCode}
              onChange={handleInputChange}
              label="Pin Code:"
            />
          </TextAreaDiv>

          <TextAreaHeader>
            <StyledTypography
              variant="h5"
              style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
            >
              Academic Details
            </StyledTypography>
          </TextAreaHeader>

          <TextAreaDiv style={{ flexDirection: "column", gap: "1rem" }}>
            {/* <StyledTextField
          name="instituteName"
          variant="filled"
          onChange={handleInputChange}
          value={formValues.instituteName}
          disabled={
            (!editMode && !newMode) ||
            editing ||
            creating ||
            searching ||
            editingProfile
          }
          label="Institution Name:"
          
          style={{ marginTop: "1vh" }}
          
          
        /> */}
            {/* <StyledTextField
          variant="filled"
          onChange={handleInputChange}
          value={formValues.pastPerformance}
          disabled={(!editMode && !newMode) || editing || creating || searching||editingProfile}
          label="Last Year Performance:"
          
          
          
        
        /> */}
            <div>
              <StyledInputLabel shrink>Preferred Exams:</StyledInputLabel>
              <SelectDiv>
                <StyledAutocomplete
                  multiple
                  options={Exams}
                  disableCloseOnSelect
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  // name="primaryTopic"
                  value={formValues.compiExams}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  onChange={(event, value) => {
                    setValues({ ...formValues, compiExams: value });
                  }}
                  // renderOption={(option, { selected }) => (
                  //   <React.Fragment>
                  //     <StyledCheckbox
                  //       icon={icon}
                  //       checkedIcon={checkedIcon}
                  //       style={{ marginRight: 8 }}
                  //       checked={selected}
                  //     />
                  //     {option}
                  //   </React.Fragment>
                  // )}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      style={{
                        width: "100%",
                        marginTop: "22px",
                        marginLeft: "1vw",
                      }}
                    />
                  )}
                />
              </SelectDiv>
            </div>
            <SelectDiv
              className="a"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <SchoolDiv>
                <StyledInputLabel
                  shrink
                  style={{ padding: "1vh 0 0 0", fontSize: "19px" }}
                >
                  Institute/School Name:
                </StyledInputLabel>
                <StyledFormHelperText>
                  type your school name and submit even if not in list.
                </StyledFormHelperText>
              </SchoolDiv>
              <div className="schoolListStyle">
                <StyledAutocomplete
                  filterOptions={filterOptions}
                  options={schoolList}
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  value={formValues.schoolName}
                  freeSolo
                  autoSelect
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    setValues({ ...formValues, schoolName: value });
                  }}
                  // renderOption={(option, { selected }) => {
                  //   return (
                  //     <React.Fragment>
                  //       <StyledCheckbox
                  //         icon={radioIcon}
                  //         checkedIcon={radioCheckedIcon}
                  //         style={{ marginRight: 8 }}
                  //         checked={selected}
                  //       />
                  //       {option}
                  //     </React.Fragment>
                  //   );
                  // }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      style={{
                        width: "100%",
                        marginTop: "22px",
                        marginLeft: "1vw",
                      }}
                    />
                  )}
                />
                <StyledTextField
                  name="sPinCode"
                  variant="filled"
                  type="number"
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  value={formValues.sPinCode}
                  onChange={handleInputChange}
                  label="Institute/School Pincode:"
                />
              </div>
            </SelectDiv>
          </TextAreaDiv>
          <TextAreaDiv>
            <SelectDiv style={{ flexDirection: "column" }}>
              <StyledInputLabel shrink>Document Type:</StyledInputLabel>
              <StyledSelect
                name="schoolDocType"
                value={formValues.schoolDocType}
                onChange={handleInputChange}
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                style={{ width: "100%", marginTop: "15px", marginLeft: "1vw" }}
              >
                {schoolDocumentList.map((document, index) => {
                  return (
                    <StyledMenuItem key={index} value={document}>
                      {document}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </SelectDiv>
            {formValues.schoolDocType ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1.5vh 0 0vh 0",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  color: "#1f5156",
                }}
              >
                <label
                  for="file-upload"
                  style={{
                    margin: "0 0 30px 0",
                  }}
                >
                  {/* {formValues.schoolDocType === "School Identity Card"
                ? "Upload School Identity Card PDF"
                : formValues.schoolDocType ===
                  "AADHAAR Card and School Admission Fee Receipt"
                ? "Upload AADHAAR Card PDF"
                : "Upload Document PDF"} */}
                  {`Upload ${formValues.schoolDocType} PDF File or Image (size limit: 2mb)`}
                </label>
                <input
                  type="file"
                  id="file-upload"
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  accept="application/pdf,image/*"
                  // hidden
                  name="filetobase64"
                  onChange={onUploadDocFileChange}
                />
                {formValues.schoolDocFile64 &&
                  formValues.schoolDocFile64 != "" ? (
                  <a href={formValues.schoolDocFile64} download="file">
                    Download Document
                  </a>
                ) : null}
                {/* {formValues.schoolDocType ===
            "AADHAAR Card and School Admission Fee Receipt" ? (
              <div>
                <label
                  for="file-upload"
                  style={
                    {
                      // margin: "0 0 2.5vh 0",
                    }
                  }
                >
                  Upload School Admission Fee Receipt PDF (size limit: 2mb)
                </label>
                <input
                  type="file"
                  id="file-upload"
                  disabled={
                    (!editMode && !newMode) ||
                    editing ||
                    creating ||
                    searching ||
                    editingProfile
                  }
                  accept="application/pdf,image/*"
                  // hidden
                  name="filetobase64"
                  onChange={onUploadReceiptFileChange}
                />
                {formValues.schoolAdmissionRec64 &&
                formValues.schoolAdmissionRec64 != "" ? (
                  <a href={formValues.schoolAdmissionRec64} download="file">
                    Download Document
                  </a>
                ) : null}
              </div>
            ) : null} */}
              </div>
            ) : null}
          </TextAreaDiv>
          <TextAreaHeader>
            <StyledTypography
              variant="h5"
              style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
            >
              KYC Details
            </StyledTypography>
          </TextAreaHeader>
          <StyledInputLabel shrink>Document Type:</StyledInputLabel>
          <TextAreaDiv style={{ alignItems: "center" }}>
            <SelectDiv>
              <StyledSelect
                name="docType"
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                value={formValues.docType}
                onChange={handleInputChange}
                style={{ width: "100%", marginTop: "15px", marginLeft: "1vw" }}
              >
                {documentList.map((document, index) => {
                  return (
                    <StyledMenuItem key={index} value={document}>
                      {document}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </SelectDiv>
            <StyledTextField
              name="docNo"
              label="Document Number:"
              value={formValues.docNo}
              onChange={handleInputChange}
              disabled={
                (!editMode && !newMode) ||
                editing ||
                creating ||
                searching ||
                editingProfile
              }
              variant="filled"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5vh 0 0vh 0",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                color: "#1f5156",
              }}
            >
              <label
                for="file-upload"
                style={{
                  margin: "0 0 30px 0",
                }}
              >
                Upload PDF File or Image (size limit: 2mb):
              </label>
              <input
                type="file"
                id="file-upload"
                disabled={
                  (!editMode && !newMode) ||
                  editing ||
                  creating ||
                  searching ||
                  editingProfile
                }
                accept="application/pdf,image/*"
                // hidden
                name="filetobase64"
                onChange={onUploadFileChange}
              />
            </div>
            {formValues.docFile64 && formValues.docFile64 != "" ? (
              <a href={formValues.docFile64} download="file">
                Download KYC Document
              </a>
            ) : null}
          </TextAreaDiv>
          <ButtonDiv>
            {newMode ? (
              <StyledButton
                variant="contained"
                color="primary"
                disabled={
                  loading || creating || searching || editing || editingProfile
                }
                size="medium"
                onClick={submitContent}
              >
                Submit
              </StyledButton>
            ) : null}
            {editMode ? (
              <StyledButton
                variant="contained"
                color="primary"
                disabled={
                  loading || creating || searching || editing || editingProfile
                }
                size="medium"
                onClick={
                  fUser.role === "Teacher" || fUser.role === "Institute"
                    ? userEditContent
                    : studentEditContent
                }
              >
                Submit
              </StyledButton>
            ) : null}
          </ButtonDiv>
        </RootDiv>
      </MainContainer>
    </>
  );
}
