import React, { useState } from "react";
import { Button, Grid } from "@mui/material";

import Header from "../header/Header";
import Content from "./Content";
import { RootDiv } from "../../css/StyledComponents";

const Courses = ({ fUser }) => {
  const flag = 1;

  return (
    <RootDiv>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Header flag={flag} />
        </Grid>

        {/* <Grid item container>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={10}>
            <Search flag={flag} />
          </Grid>
          <Grid item xs={false} sm={1} />
        </Grid> */}

        <Grid item container>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={10}>
            <Content fUser={fUser} flag={flag} />
          </Grid>
          <Grid item xs={false} sm={1} />
        </Grid>
      </Grid>
    </RootDiv>
  );
};

export default Courses;
