import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery, NetworkStatus } from "@apollo/client";
import { createTheme, ThemeProvider, Hidden } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  RootDiv,
  TextAreaDiv,
  StyledTextField,
  StyledButton,
  StyledActiveButton,
  StyledIconButton,
  StyledNav,
  StyledDrawer,
  StyledImg,
  ToolBarDiv,
  LeftDrawerDiv,
  ColumnFlexDiv,
  StyledTypography
} from "../../css/StyledComponents";

import AddContent from "./addContent";

const getCourseQuery = loader(
  "../../graphqlCalls/course/teacherGetACourse.gql"
);

export default function CourseScreen(props) {
  // console.log("cs props", props);
  const { window } = props;
  const { state } = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [Dets, setDets] = useState({
    TertiaryTopics: [{ name: "Loading..." }],
    ...state,
  });
  const [active, setActive] = useState(Dets.TertiaryTopics[0].name);
  const navigate = useNavigate();

  // console.log("cs state dets", Dets);

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getCourseQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: props.fUser ? props.fUser._id : null,
        cId: Dets?.id,
        // userEmail: "chinmay.irock@gmail.com",
      },
      skip: !state || !props.fUser,
      onError: (err) => {
        // console.log("err", err);
        // refetch()
        alert("Something went wrong");
      },
      onCompleted: ({ teacherGetACourse }) => {
        const tts = teacherGetACourse.topicData.map((item) => {
          return { name: item.tertiaryTopic };
        });
        setDets({ ...Dets, TertiaryTopics: tts });
        //no check for tts=[] cuz not allowing empty tertiary topic array
        setActive(tts[0].name);
      },
    }
  );
  // console.log("in Main Flow networkStat is-->", networkStatus);
  if (networkStatus === NetworkStatus.ready) {
    if (data) {
      // console.log("query", data);
      //   fetchedUser = data.getUser;
      // setFetchedUser(data.getUser);
      // console.log(fetchedUser);
      // setUser(employee);
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Dets.TertiaryTopics.push({name:"test1"}) //temp fix. DON'T FORGET TO REMOVE LATER

  const drawer = (
    <ColumnFlexDiv style={{ marginTop: "70px",gap:"10px"}}>
      <ToolBarDiv>
      <StyledTypography style={{ fontWeight: "bold",fontSize:"clamp(12px, 2vw, 16px)'" }}>
          Tertiary Topics
        </StyledTypography>
      </ToolBarDiv>
      {Dets.TertiaryTopics?.map((item, index) => {
        const newTheme = createTheme({
          overrides: {
            Muibutton: {
              label: {
                justifyContent: "justify",
              },
            },
          },
        });
        return (
          <ThemeProvider theme={newTheme} key={index}>
            {active === item.name ? (
              <StyledActiveButton onClick={() => setActive(item.name)} style={{width:"80%",whiteSpace:"normal",overflow:"break-word" }}>
                {item.name}
              </StyledActiveButton>
            ) : (
              <StyledButton onClick={() => setActive(item.name)}  style={{width:"80%",whiteSpace:"normal",overflow:"break-word" }}>
                {item.name}
              </StyledButton>
            )}
          </ThemeProvider>
        );
      })}
    </ColumnFlexDiv>
  );

  //what is this for? window is undef in props
  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (!state) {
    navigate("/courses");
    return null;
  }

  return (
    <RootDiv>
      <StyledIconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
      >
        <StyledImg
          src="https://cdn.iconscout.com/icon/free/png-512/menu-bar-lines-option-list-hamburger-web-6-3228.png"
          alt="MenuIcon"
        />
      </StyledIconButton>
      <StyledNav aria-label="Tertiary topics">
      <StyledDrawer
          variant="temporary"
          // anchor={theme.direction === "rtl" ? "right" : "left"}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, //Better open performance on mobile.
          }}
        >
          {drawer}
        </StyledDrawer>
        <StyledDrawer
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          variant="permanent"
          open
        >
          {drawer}
        </StyledDrawer>
      </StyledNav>
      <LeftDrawerDiv>
      <TextAreaDiv>
        <StyledTextField
          variant="filled"
          disabled
          label="Primary Topic"
          multiline
          rows={1}
          value={Dets.primaryTopic ? Dets.primaryTopic : ""}
        />
        <StyledTextField
          variant="filled"
          disabled
          label="Secondary Topic"
          multiline
          rows={1}
          value={Dets.secondaryTopic ? Dets.secondaryTopic : ""}
        />
        <div>
          <StyledTextField
            disabled
            variant="filled"
            multiline
            rows={2}
            value={active}
            label="Tertiary Topic"
          />
        </div>
      </TextAreaDiv>

      <div>
        {active != "Loading..." ? (
          <AddContent tTopic={active} fUser={props.fUser} cId={Dets.id} />
        ) : null}
      </div>
      </LeftDrawerDiv>
    </RootDiv>
  );
}
