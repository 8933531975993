import React, { useState } from "react";
import { gql, useMutation, NetworkStatus } from "@apollo/client";
import { loader } from "graphql.macro";
import { useNavigate } from "react-router-dom";
import {
  RootDiv,
  TextAreaDiv,
  StyledButton,
  StyledSelect,
  StyledMenuItem,
} from "../../css/StyledComponents";

import { topics } from "../../constants/topics";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";

import "./SubjectSelectionStyles.css";

const createCoursesMutation = loader(
  "../../graphqlCalls/course/TeacherCreateCourse.gql"
);
const getCoursesQuery = loader(
  "../../graphqlCalls/course/userGetAllCourses.gql"
);

const initialFormValues = {
  primaryTopic: "",
  secondaryTopic: "",
};

export default function SubjectSelection({ fUser }) {
  const [formValues, setValues] = useState(initialFormValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const [
    CreateCoursesMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createCoursesMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Submitted!");
    },
  });

  const updateCacheCreating = (client, { data: TeacherCreateCourse }) => {
    const data = client.readQuery({
      query: getCoursesQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
      },
    });
    if (data) {
      const newCoursesList = {
        userGetAllCourses: [TeacherCreateCourse, ...data.userGetAllCourses],
      };
      client.writeQuery({
        query: getCoursesQuery,
        variables: {
          userId: fUser._id,
          userT: fUser.role,
        },
        data: newCoursesList,
      });
    }
  };
  async function showDetails() {
    try {
      const callInfo = await CreateCoursesMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            primaryTopic: formValues.primaryTopic,
            secondaryTopic: formValues.secondaryTopic,
          },
        },
        update: updateCacheCreating,
      });
      navigate("/course", {
        state: {
          id: callInfo.data.TeacherCreateCourse._id,
          primaryTopic: callInfo.data.TeacherCreateCourse.primaryTopic,
          secondaryTopic: callInfo.data.TeacherCreateCourse.secondaryTopic,
        },
      });
    } catch (error) {
      alert("Something went wrong");
    }
  }

  return (
    <RootDiv>
      {NotificationsContainer()}
      <TextAreaDiv style={{margin:"0 auto"}}>
        <div>
          <p
            style={{
              color: "#1f5156",
              fontSize: "16px",
            }}
          >
            Primary Topic:
          </p>
          <StyledSelect
            name="primaryTopic"
            value={formValues.primaryTopic}
            onChange={handleInputChange}
          >
            {Object.keys(topics).map((pTopic, index) => {
              return (
                <StyledMenuItem key={index} value={pTopic}>
                  {pTopic}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </div>
        <div>
          <p
            style={{
              // margin: "3.25vh 0vw 0vh -14.25vw",
              color: "#1f5156",
              fontSize: "16px",
            }}
          >
            Secondary Topic:
          </p>
          <StyledSelect
            name="secondaryTopic"
            value={formValues.secondaryTopic}
            onChange={handleInputChange}
          >
            {formValues.primaryTopic !== ""
              ? Object.keys(topics[formValues.primaryTopic]).map(
                  (sTopic, sIndex) => {
                    return (
                      <StyledMenuItem key={sIndex} value={sTopic}>
                        {sTopic}
                      </StyledMenuItem>
                    );
                  }
                )
              : null}
          </StyledSelect>
        </div>
      </TextAreaDiv>
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        disabled={creating}
        onClick={showDetails}
        style={{margin:"2% auto"}}
      >
        Submit
      </StyledButton>
    </RootDiv>
  );
}
