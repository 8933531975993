import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Tooltip } from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { Edit, Delete } from "@mui/icons-material";

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogActions,
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
  StyledBox,
  StyledChip,
  StyledCheckbox,
  StyledListItemText,
  StyledTypography,
  StyledTextField,
  StyledButton,
  RootDiv,
  TextAreaDiv,
  SelectDiv,
  TextAreaHeader,
  ButtonDiv,
  MainContainer,
} from "../profileStyle/style";
import Header from "../../header/Header";
import { examsList } from "../../../constants/examsList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
import { LoadingSpinner } from "../../../constants/loadingSpinner";

const instituteGetProfileQuery = loader(
  "../../../graphqlCalls/institute/instituteGetProfile.gql"
);
const instituteEditProfileMutation = loader(
  "../../../graphqlCalls/institute/InstituteEditProfile.gql"
);
const deleteInstiUserMutation = loader(
  "../../../graphqlCalls/institute/UserDeleteInstiUser.gql"
);

const initialFormValues = {
  instituteName: "",
  email: "",
  mobileNo: "",
  address1: "",
  address2: "",
  pinCode: 0,
  bankName: "",
  branchName: "",
  accountNo: "",
  IFSCcode: "",
  panNo: "",
  docType: "",
  docNo: "",
  docFile64: "",
  instituteUsers: [],
  compiExams: [],
  price: 0,
};

const InstituteProfile = (props) => {
  const fUser = props.fUser;
  const { state } = useLocation();

  const [editMode, setEditMode] = useState(false);
  const [formValues, setValues] = useState(initialFormValues);
  // const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null);
  const documentList = [
    "PAN Card",
    "Shop Act Registration",
    "Certificate of Registration(CIN)",
    "GSTIN",
  ];
  const instituteUserType = ["Admin", "Employee"];

  const [instiUserToDelete, setInstiUserToDelete] = useState("");
  const [instiUserToDeleteName, setInstiUserToDeleteName] = useState("");

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          docFile64: result,
        });
        setFileName(target.files[0]);
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleInstiUsersInputChange = (event, index) => {
    const { name, value } = event.target;
    let instiUsers = formValues.instituteUsers;
    let newInstiUsersArray = [...instiUsers];
    let newInstiUserObj = { ...newInstiUsersArray[index] };
    newInstiUserObj[`${name}`] = value;
    newInstiUsersArray[index] = newInstiUserObj;
    setValues({ ...formValues, instituteUsers: newInstiUsersArray });
  };

  const addNewInstiUser = () => {
    let instiUsers = formValues.instituteUsers;
    instiUsers.push({
      uId: "",
      uEmail: "",
      uFirstName: "",
      uMiddleName: "",
      uLastName: "",
      uMobileNo: "",
      uType: "",
    });
    setValues({ ...formValues, instituteUsers: instiUsers });
  };
  const cancelInstiUser = (index) => {
    let instiUsers = formValues.instituteUsers;
    instiUsers.splice(index, 1);
    setValues({ ...formValues, instituteUsers: instiUsers });
  };
  const formatInstiUsers = (fetchedInstiUsers) => {
    let formattedUsers = fetchedInstiUsers.map((instiUser) => {
      return {
        uId: instiUser.user._id,
        uFirstName: instiUser.user.portrait.alias.first,
        uMiddleName: instiUser.user.portrait.alias.middle,
        uLastName: instiUser.user.portrait.alias.last,
        uEmail: instiUser.user.portrait.pingAt.virtualAdd,
        uMobileNo: instiUser.user.portrait.pingAt.cell,
        uType: instiUser.category,
      };
    });
    return formattedUsers;
  };
  const [
    InstituteEditProfileMutation,
    {
      loading: editingProfile,
      error: errorEditingProfile,
      data: dataEditingProfile,
    },
  ] = useMutation(instituteEditProfileMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(
        err.message == "Error: Duplicate Email,User Already Exists!"
          ? err.message
          : "Something went wrong"
      );
    },
    onCompleted: () => {
      NotificationsSuccess("Edited!");
    },
  });

  const { teacherData, loading, refetch } = useQuery(instituteGetProfileQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      iId: fUser ? fUser._id : null,
      // userEmail: "chinmay.irock@gmail.com",
    },
    skip: !state || !fUser || fUser.role != "Institute",
    onError: (err) => {
      // console.log(JSON.stringify(err, null, 2));
      alert("Something went wrong");
      // refetch()
    },
    onCompleted: ({ instituteGetProfile }) => {
      setValues({
        ...formValues,
        instituteName: instituteGetProfile.portrait.alias.first,
        email: instituteGetProfile.portrait.pingAt.virtualAdd,
        mobileNo: instituteGetProfile.portrait.pingAt.cell,
        address1: instituteGetProfile.portrait.address.line1,
        address2: instituteGetProfile.portrait.address.line2,
        pinCode: instituteGetProfile.portrait.address.pinCode,
        docType: instituteGetProfile.portrait.docType,
        docNo: instituteGetProfile.portrait.docNo,
        docFile64: instituteGetProfile.portrait.docFile64,
        bankName: instituteGetProfile.portrait.bankDets.bankName,
        branchName: instituteGetProfile.portrait.bankDets.branchName,
        accountNo: instituteGetProfile.portrait.bankDets.accountNo,
        IFSCcode: instituteGetProfile.portrait.bankDets.IFSCcode,
        panNo: instituteGetProfile.portrait.bankDets.panNo,
        compiExams: instituteGetProfile.preferredCompiExam,
        instituteUsers: instituteGetProfile.instiUsers
          ? formatInstiUsers(instituteGetProfile.instiUsers)
          : [],
        price: instituteGetProfile.pricing.price,
      });
    },
  });

  const [
    DeleteInstiUserMutation,
    { loading: deleting, error: errorDeleting, data: dataDeleting },
  ] = useMutation(deleteInstiUserMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted(data) {
      NotificationsSuccess("Deleted!");
      refetch();
    },
  });
  const deleteInstiUser = async () => {
    if (deleting) return;
    try {
      const callInfo = await DeleteInstiUserMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            email: instiUserToDelete,
          },
        },
      });
    } catch (error) {}
  };
  const updateCacheInstituteEditing = (
    client,
    { data: InstituteEditProfile }
  ) => {
    let editableInstituteProfile = InstituteEditProfile;
    if (editableInstituteProfile.instiUsers) {
      let formattedInstiUsers = formatInstiUsers(
        editableInstituteProfile.instiUsers
      );
      editableInstituteProfile.instiUsers = formattedInstiUsers;
    }
    const wt = client.writeQuery({
      query: instituteGetProfileQuery,
      variables: {
        iId: fUser._id,
      },
      data: { instituteGetProfile: editableInstituteProfile },
    });
    setEditMode(false);
  };

  const instituteEditContent = async () => {
    try {
      const callInfo = await InstituteEditProfileMutation({
        variables: {
          input: {
            instituteId: fUser._id,
            instituteName: formValues.instituteName,
            mobileNo: formValues.mobileNo,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            bankName: formValues.bankName,
            branchName: formValues.branchName,
            accountNo: formValues.accountNo,
            IFSCcode: formValues.IFSCcode,
            panNo: formValues.panNo,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            instituteUsers: formValues.instituteUsers,
            pricing: { price: parseInt(formValues.price) },
            // topicData: [topicDataTypeIp],
          },
        },

        update: updateCacheInstituteEditing,
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (!state) navigate("/", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }
  return (
    <>
      <Header flag={11} fUser={fUser} profileName={"Institute Profile"} />
      <MainContainer>
        <RootDiv>
          {NotificationsContainer()}
          <TextAreaHeader>
            <StyledButton
              variant="contained"
              size="large"
              startIcon={<Edit />}
              onClick={() => {
                setEditMode(!editMode);
              }}
              disabled={loading || editingProfile}
            >
              Edit
            </StyledButton>
          </TextAreaHeader>
          <TextAreaHeader>
            <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
              Institute Details
            </StyledTypography>
          </TextAreaHeader>
          <TextAreaDiv>
            <StyledTextField
              name="instituteName"
              label="Institute Name:"
              value={formValues.instituteName}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="email"
              label="Email ID:"
              value={formValues.email}
              disabled
              variant="filled"
            />
            <StyledTextField
              name="mobileNo"
              value={formValues.mobileNo}
              onChange={handleInputChange}
              variant="filled"
              disabled={!editMode || editingProfile || loading || deleting}
              label="Mobile No:"
            />
          </TextAreaDiv>
          <TextAreaDiv>
            <StyledTextField
              name="price"
              label="Annual Subscription Fee:"
              value={formValues.price}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
          </TextAreaDiv>

          <TextAreaDiv>
            <StyledTextField
              name="address1"
              label="Address Line 1:"
              value={formValues.address1}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="address2"
              label="Address Line 2:"
              value={formValues.address2}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="pinCode"
              label="Pin code:"
              type="number"
              value={formValues.pinCode}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
          </TextAreaDiv>
          <StyledInputLabel shrink>Preferred Exams:</StyledInputLabel>
          <TextAreaDiv>
            <StyledSelect
              name="compiExams"
              multiple
              value={formValues.compiExams}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              renderValue={(selected) => (
                <StyledBox
                  style={{ display: "flex", flexWrap: "wrap", padding: 0.7 }}
                >
                  {selected.map((value) => (
                    <StyledChip
                      key={value}
                      label={value}
                      style={{ margin: 1 }}
                    />
                  ))}
                </StyledBox>
              )}
            >
              {examsList.map((exam, index) => {
                return (
                  <StyledMenuItem key={index} value={exam}>
                    <StyledCheckbox
                      checked={formValues.compiExams.indexOf(exam) > -1}
                    />
                    <StyledListItemText primary={exam} />
                  </StyledMenuItem>
                );
              })}
            </StyledSelect>
          </TextAreaDiv>

          <TextAreaHeader>
            <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
              User Details
            </StyledTypography>
          </TextAreaHeader>
          <StyledDialog maxWidth="md" open={open} disableEscapeKeyDown>
            <StyledDialogContent>
              <Paper>
                Are You Sure you want to Delete {instiUserToDeleteName} ?
              </Paper>
            </StyledDialogContent>
            <StyledDialogActions>
              <StyledButton
                variant="contained"
                onClick={async () => {
                  await deleteInstiUser();
                  setOpen(false);
                }}
              >
                Yes,Delete
              </StyledButton>
              <StyledButton
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </StyledButton>
            </StyledDialogActions>
          </StyledDialog>
          {formValues.instituteUsers.map((instiUser, index) => {
            return (
              <div key={index}>
                <TextAreaDiv>
                  <StyledTextField
                    name="uFirstName"
                    value={instiUser.uFirstName}
                    onChange={(event) => {
                      handleInstiUsersInputChange(event, index);
                    }}
                    variant="filled"
                    disabled={
                      !editMode || editingProfile || loading || deleting
                    }
                    label="First Name:"
                  />
                  <StyledTextField
                    name="uMiddleName"
                    value={instiUser.uMiddleName}
                    onChange={(event) => {
                      handleInstiUsersInputChange(event, index);
                    }}
                    variant="filled"
                    disabled={
                      !editMode || editingProfile || loading || deleting
                    }
                    label="Middle Name:"
                  />
                  <StyledTextField
                    name="uLastName"
                    value={instiUser.uLastName}
                    onChange={(event) => {
                      handleInstiUsersInputChange(event, index);
                    }}
                    variant="filled"
                    disabled={
                      !editMode || editingProfile || loading || deleting
                    }
                    label="Last Name:"
                  />
                  {instiUser.uId === "" ? (
                    <StyledButton
                      variant="contained"
                      size="small"
                      onClick={() => cancelInstiUser(index)}
                    >
                      Cancel
                    </StyledButton>
                  ) : (
                    <Tooltip title="Remove">
                      <StyledButton
                        disabled={
                          !editMode || editingProfile || loading || deleting
                        }
                        onClick={() => {
                          setInstiUserToDeleteName(`${instiUser.uFirstName} 
                    ${instiUser.uLastName}`);
                          setInstiUserToDelete(instiUser.uEmail);
                          setOpen(true);
                        }}
                        size="large"
                        startIcon={<Delete />}
                      >
                        Delete
                      </StyledButton>
                    </Tooltip>
                  )}
                </TextAreaDiv>
                <TextAreaDiv>
                  <StyledTextField
                    name="uEmail"
                    label="Email ID:"
                    onChange={(event) => {
                      handleInstiUsersInputChange(event, index);
                    }}
                    value={instiUser.uEmail}
                    disabled={
                      !editMode ||
                      editingProfile ||
                      loading ||
                      deleting ||
                      instiUser.uId != ""
                    }
                    variant="filled"
                  />
                  <StyledTextField
                    name="uMobileNo"
                    value={instiUser.uMobileNo}
                    onChange={(event) => {
                      handleInstiUsersInputChange(event, index);
                    }}
                    variant="filled"
                    disabled={
                      !editMode || editingProfile || loading || deleting
                    }
                    label="Mobile No:"
                  />
                  <SelectDiv>
                    <StyledInputLabel shrink>User Type:</StyledInputLabel>
                    <StyledSelect
                      name="uType"
                      value={instiUser.uType}
                      onChange={(event) => {
                        handleInstiUsersInputChange(event, index);
                      }}
                      disabled={
                        !editMode || editingProfile || loading || deleting
                      }
                      //
                      //
                      style={{
                        width: "100%",
                        marginTop: "1vh",
                        marginLeft: "1vw",
                      }}
                    >
                      {instituteUserType.map((userType, index2) => {
                        return (
                          <StyledMenuItem key={index2} value={userType}>
                            {userType}
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </SelectDiv>
                </TextAreaDiv>
              </div>
            );
          })}
          <TextAreaHeader>
            <StyledButton
              disabled={!editMode || editingProfile || loading || deleting}
              variant="contained"
              onClick={addNewInstiUser}
            >
              + Add New User
            </StyledButton>
          </TextAreaHeader>
          <TextAreaHeader>
            <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
              Bank Details
            </StyledTypography>
          </TextAreaHeader>
          <TextAreaDiv>
            <StyledTextField
              name="bankName"
              label="Bank Name:"
              value={formValues.bankName}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="branchName"
              label="Branch Name:"
              value={formValues.branchName}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="accountNo"
              label="Account Number:"
              value={formValues.accountNo}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
          </TextAreaDiv>
          <TextAreaDiv>
            <StyledTextField
              name="IFSCcode"
              label="IFSC Code:"
              value={formValues.IFSCcode}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <StyledTextField
              name="panNo"
              label="PAN Card No:"
              value={formValues.panNo}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
          </TextAreaDiv>

          <TextAreaHeader>
            <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
              KYC Details
            </StyledTypography>
          </TextAreaHeader>
          <StyledInputLabel shrink>Document Type:</StyledInputLabel>
          <TextAreaDiv>
            <SelectDiv>
              <StyledSelect
                name="docType"
                value={formValues.docType}
                onChange={handleInputChange}
                disabled={!editMode || editingProfile || loading || deleting}
                //
                //
                style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
              >
                {documentList.map((document, index) => {
                  return (
                    <StyledMenuItem key={index} value={document}>
                      {document}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </SelectDiv>
            <StyledTextField
              name="docNo"
              label="Document Number:"
              value={formValues.docNo}
              onChange={handleInputChange}
              disabled={!editMode || editingProfile || loading || deleting}
              variant="filled"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5vh 0 0vh 0",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                color: "#1f5156",
              }}
            >
              <label
                for="file-upload"
                style={{
                  margin: "0 0 30px 0",
                }}
              >
                Upload PDF File or Image (size limit: 2mb):
              </label>
              <input
                type="file"
                id="file-upload"
                disabled={!editMode || editingProfile || loading || deleting}
                accept="application/pdf,image/*"
                // hidden
                name="filetobase64"
                onChange={onUploadFileChange}
              />
            </div>
            {formValues.docFile64 && formValues.docFile64 != "" ? (
              <a href={formValues.docFile64} download="file">
                Download KYC Document
              </a>
            ) : null}
          </TextAreaDiv>
          <br />
          <br />
          <ButtonDiv>
            {editMode ? (
              <StyledButton
                variant="contained"
                disabled={loading || editingProfile}
                onClick={instituteEditContent}
              >
                Submit
              </StyledButton>
            ) : null}
          </ButtonDiv>
        </RootDiv>
      </MainContainer>
    </>
  );
};

export default InstituteProfile;
