import React from "react";

import Header from "../header/Header";
import SubjectSelection from "./SubjectSelection";

export const NewCourse = ({ fUser }) => {
  return (
    <>
      <Header flag={8} />
      <SubjectSelection fUser={fUser} />
    </>
  );
};
