import React, { useState, useEffect, useCallback } from "react";

import Header from "../header/Header";
import TestContent from "./testList/TestContent";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";

const Tests = (props) => {
  const flag = 3;
  const fUser = props.fUser;
  const setUrl = props.setUrl;

  useEffect(() => {
    if (fUser.role === "Student") setUrl(window.location.pathname);
  }, [fUser.role, setUrl]);

  const [filter, setFilter] = useState("");

  const filterValueSetter = useCallback((value) => {
    setFilter(value);
  }, []);
  return (
    <RootDiv>
      <Header
        flag={flag}
        fUser={fUser}
        filterValueSetter={filterValueSetter}
        filterValue={filter}
      />

      <StyledGrid item xs={12} sm={12}>
        <TestContent flag={flag} fUser={fUser} filter={filter} />
      </StyledGrid>
    </RootDiv>
  );
};

export default Tests;
